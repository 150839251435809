import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../common/constants';

const useStyles = makeStyles({
  root: {
    minHeight: 'calc(100vh - 64px)',
    paddingTop: '64px',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/aboutHeader.jpg)`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '200px',
    width: '100%',
    textAlign: 'center',
    fontSize: '2em',
    color: colors.font,
    filter: 'saturate(150%)',
    lineHeight: '200px',
    fontWeight: 600,
    WebkitTextStroke: '1px',
    WebkitTextStrokeColor: colors.accentFont,
    ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
      height: '64px',
      lineHeight: '64px',
    },
  },
  contentContainer: {
    flex: '1',
    padding: '10%',
    textAlign: 'center',
    fontSize: '1.5em'
  },
});

export const About: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        About us
      </div>
      <div className={classes.contentContainer}>
        We develop indie games for mobile, web and PC.
        We strive to develop games with unique and creative ideas.
        We are still learning and have big dreams, please don't hesitate to reach out with any feedback.
      </div>
    </div>
  );
};
