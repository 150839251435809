import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { colors, featuredGame } from '../common/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';

const useStyles = makeStyles({
  root: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/srt/featured.png)`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  titleContainer: {
    color: colors.font,
    fontSize: 'clamp(2rem, -0.8750rem + 8.3333vw, 3.5rem)',
    fontWeight: 700,
    WebkitTextStroke: '2px',
    WebkitTextStrokeColor: colors.main,
    display: 'grid',
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    alignItems: 'center',
    paddingTop: '40px',
  },
  trailerContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px',
    // backgroundColor: colors.font,
  },
  learnMoreButton: {
    borderRadius: '15px',
    padding: '10px',
    minWidth: '200px',
    fontSize: '1.2em',
    border: 'none',
    cursor: 'pointer',
    textAlign: 'center',
    textDecoration: 'none',
    color: colors.main,
    backgroundColor: colors.accent,
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: colors.font,
      border: `2px solid ${colors.accent}`,
    },
  },
  learnMoreButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  youtubeIcon: {
    color: colors.font,
  },
  videoPlaybackContainer: {
    height: '500px',
    width: 'min(100%, 1000px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `3px solid ${colors.accent}`,
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundImage: `url(${process.env.PUBLIC_URL}/srt/featured.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '&:hover': {
      filter: 'saturate(150%)',
      '& svg': {
        color: colors.accent,
      },
    },
  },
});

export const FeaturedGame: React.FC = () => {
  const classes = useStyles();
  const { title, learnMoreLink, trailerLink } = featuredGame;
  const [videoLoaded, updateVideoLoaded] = React.useState(false);
  const handleYoutubeClick = () => {
    updateVideoLoaded(true);
  };
  const videoContainer = videoLoaded ? (
    <iframe
      title="Trailer"
      width="768"
      height="432"
      src={`${trailerLink}?autoplay=1`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  ) : (
    <div className={classes.videoPlaybackContainer} onClick={handleYoutubeClick}>
      <FontAwesomeIcon icon={faYoutubeSquare} size="4x" className={classes.youtubeIcon} title="Youtube" />
    </div>
  );
  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>{title}</div>
      <div className={classes.learnMoreButtonContainer}>
        <Link to={learnMoreLink} className={classes.learnMoreButton}>
          Learn more
        </Link>
      </div>
      <div className={classes.trailerContainer}>{videoContainer}</div>
    </div>
  );
};
