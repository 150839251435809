import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../common/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare, faYoutubeSquare, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.main,
    color: colors.font,
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
  },
  socialContainer: {
    color: colors.font,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '2%',
  },
  socialIcon: {
    padding: '5px',
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    color: colors.font,
  },
  linkContainer: {
    flex: 1,
  },
});

export const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.linkContainer}>
        <div>Smakit Games LLP</div>
      </div>
      <div className={classes.socialContainer}>
        <a
          href="https://www.youtube.com/@smakitgames"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <FontAwesomeIcon icon={faYoutubeSquare} size="lg" className={classes.socialIcon} title="Youtube" />
        </a>
        <a href="mailto:smakitgamesllp@gmail.com" target="_blank" rel="noopener noreferrer" className={classes.link}>
          <FontAwesomeIcon icon={faEnvelope} size="lg" className={classes.socialIcon} title="Email" />
        </a>
      </div>
    </div>
  );
};
