import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { colors } from '../common/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitterSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { faBars, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles({
  root: {
    height: '64px',
    width: '100%',
    backgroundColor: colors.main,
    display: 'flex',
    boxShadow: '0px 0px 16px rgba(0,0,0,.9)',
    zIndex: 100,
    position: 'absolute',
  },
  socialContainer: {
    color: colors.font,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
  socialIcon: {
    padding: '12px',
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    color: colors.font,
  },
  linksContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
  linkDivContainer: {
    margin: '0 20px',
  },
  navigationLink: {
    color: colors.font,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '1.2em',
    '&:hover': {
      color: colors.accent,
    },
  },
  collapseContainer: {
    display: 'none',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
    color: colors.font,
    marginRight: '10px',
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'flex',
    },
  },
  collapsibleContainerItems: {
    position: 'absolute',
    top: '64px',
    width: '100%',
    backgroundColor: colors.font,
    padding: '10px',
    zIndex: 10,
    border: `1px solid ${colors.accentFont}`,
    ['@media (min-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
    '& div': {
      padding: '10px',
      borderBottom: `1px solid ${colors.accent}`,
      '& a': {
        color: colors.main,
      },
    },
  },
});

export const NavigationHeader: React.FC = () => {
  const classes = useStyles();
  const [menuExpanded, hideMenuExpanded] = React.useState(false);
  const handleCollapseIconClick = () => {
    hideMenuExpanded(!menuExpanded);
  };
  const linkItems = (
    <>
      <div className={classes.linkDivContainer}>
        <Link to="/" className={classes.navigationLink}>
          Home
        </Link>
      </div>
      <div className={classes.linkDivContainer}>
        <Link to="/games" className={classes.navigationLink}>
          Games
        </Link>
      </div>
      <div className={classes.linkDivContainer}>
        <Link to="/about" className={classes.navigationLink}>
          About
        </Link>
      </div>
    </>
  );
  const socialIcons = (
    <>
      <a href="https://www.youtube.com/@smakitgames" target="_blank" rel="noopener noreferrer" className={classes.link}>
        <FontAwesomeIcon icon={faYoutubeSquare} size="lg" className={classes.socialIcon} title="Youtube" />
      </a>
      <a href="mailto:smakitgamesllp@gmail.com" target="_blank" rel="noopener noreferrer" className={classes.link}>
        <FontAwesomeIcon icon={faEnvelope} size="lg" className={classes.socialIcon} title="Email" />
      </a>
    </>
  );
  const expandedMenu = menuExpanded && <div className={classes.collapsibleContainerItems}>{linkItems}</div>;
  return (
    <>
      <div className={classes.root}>
        <div>
          <Link to="/">
            <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="smakit games logo" height="64px" />
          </Link>
        </div>
        <div className={classes.collapseContainer}>
          <FontAwesomeIcon
            onClick={handleCollapseIconClick}
            icon={faBars}
            size="lg"
            className={classes.socialIcon}
            title="Facebook"
          />
        </div>
        <div className={classes.linksContainer}>{linkItems}</div>
        <div className={classes.socialContainer}>{socialIcons}</div>
      </div>
      {expandedMenu}
    </>
  );
};
