import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { HomeComponent, NavigationHeader, Footer, GameHome, GameRouter, About } from '../components';

const Home: React.FC = () => {
  return (
    <Router>
      <NavigationHeader />
      <Switch>
        <Route path="/games/:id">
          <GameRouter />
        </Route>
        <Route path="/games">
          <GameHome />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/">
          <HomeComponent />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
};

export default Home;
