import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {Helmet} from 'react-helmet';
import { colors, gameDetails } from '../common/constants';
import { GameCard } from '../common';

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.font,
    minHeight: 'calc(100vh - 64px)',
    paddingTop: '64px',
  },
  header: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/gamesHeader.jpg)`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '200px',
    width: '100%',
    textAlign: 'center',
    fontSize: '2em',
    color: colors.font,
    filter: 'saturate(150%)',
    lineHeight: '200px',
    fontWeight: 600,
    WebkitTextStroke: '1px',
    WebkitTextStrokeColor: colors.accentFont,
    ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
      height: '64px',
      lineHeight: '64px',
    },
  },
  gameCardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyItems: 'center',
    padding: '0 10px',
    gap: '10px',
  },
});

export const GameHome: React.FC = () => {
  const classes = useStyles();
  const gameCardComponents = gameDetails.filter(gameDetail => gameDetail.shouldShowInMainPage).map((gameDetail, index) => {
    const {title, releaseStatus, learnMoreLink, releaseLinks, featuredImage} = gameDetail;
    return (
      <GameCard
        key={index}
        title={title}
        releaseStatus={releaseStatus}
        learnMoreLink={learnMoreLink}
        releaseLinks={releaseLinks}
        featuredImage={featuredImage}
        alternateWidth={true}
      />
    );
  });
  return (
    <div className={classes.root}>
      <Helmet>
        <meta property="og:title" content="Games by Smakit games" />
        <meta property="og:description" content="" />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/lightcube/featured.jpeg`} />
      </Helmet>
      <div className={classes.header}>
        Our Games
      </div>
      <div className={classes.gameCardsContainer}>
        {gameCardComponents}
      </div>
    </div>
  );
};
