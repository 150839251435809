import { lightCubePolicy } from './privacyPolicy';

export const colors = {
  main: '#151C1F',
  font: '#f5f5f5',
  accent: '#A4BFC9',
  accentFont: '#4d7483',
};

export const featuredGame = {
  title: 'Super Rocket Turtle',
  learnMoreLink: '/games/srt',
  trailerLink: 'https://www.youtube.com/embed/rVo9hYb3cfY?si=eXS8WKfxZst4Tsy7',
};

export enum ReleaseStatus {
  ComingSoon,
  Released,
};

export enum ReleaseTarget {
  android,
  steam,
  ios,
}

export type ReleaseLinks = {[key in ReleaseTarget]?: string};

export interface GameDetail {
  key: string;
  title: string;
  learnMoreLink: string;
  featuredImage: string;
  shouldShowInMainPage: boolean;
  releaseStatus: ReleaseStatus,
  releaseLinks?: ReleaseLinks;
  description: string;
  videos: string[];
  images: string[];
  privacyPolicy: string;
}

const bwaDescription = `
Blind wizards assistant is an unique turn based RPG where you play as an assistant to mighty but blind wizard.
He enlisted your services to take him to castle of kuruk.
In the journey you slowly gain knowledge to create potions which heal and help wizard to fend off the monsters.
Become the ultimate potion master and help wizard to conquer the monsters.
`;

const lightCubeDescription = `
Light cube is a small addictive puzzle game for mobile devices.
Your goal is to make path between light sources and destination blocks. Later levels will have multiple light sources and destinations.
They are differentiated by colors and sometimes you even have to mix colors to get to the finish point. 
The aim is to light up the end block by creating a way for the light to pass across the cube from the source block. 
Each block can be rotated in four directions and  the whole cube can be rotated in four directions as well.
`;

const srtDescription = `
Super Rocket Turtle is a fast-paced 3D precision platformer that draws inspiration from acclaimed titles like Celeste and Super Meat Boy. Navigate through a total of 60 levels spread across four distinct worlds, each presenting a unique set of challenges.
`;

export const gameDetails: GameDetail[] = [
  {
    key: 'srt',
    title: 'Super Rocket Turtle',
    learnMoreLink: '/games/srt',
    featuredImage: `${process.env.PUBLIC_URL}/srt/featured.png`,
    shouldShowInMainPage: true,
    releaseStatus: ReleaseStatus.ComingSoon,
    description: srtDescription,
    videos: [],
    images: [`${process.env.PUBLIC_URL}/srt/media/placeholder.gif`, `${process.env.PUBLIC_URL}/srt/media/1.png`, `${process.env.PUBLIC_URL}/srt/media/screenshot (2).png`,
    `${process.env.PUBLIC_URL}/srt/media/screenshot (3).png`, `${process.env.PUBLIC_URL}/srt/media/screenshot (4).png`,
    `${process.env.PUBLIC_URL}/srt/media/screenshot (7).png`, `${process.env.PUBLIC_URL}/srt/media/screenshot (8).png`],
    privacyPolicy: '',
  },
  {
    key: 'bwa',
    title: 'Blind wizard\'s assistant',
    learnMoreLink: '/games/bwa',
    featuredImage: `${process.env.PUBLIC_URL}/bwa/featured.jpeg`,
    shouldShowInMainPage: true,
    releaseStatus: ReleaseStatus.Released,
    releaseLinks: {
      [ReleaseTarget.steam]: 'https://store.steampowered.com/app/1871160/Blind_wizards_assistant/',
    },
    description: bwaDescription,
    videos: [],
    images: [`${process.env.PUBLIC_URL}/media/1.png`, `${process.env.PUBLIC_URL}/media/2.png`,
    `${process.env.PUBLIC_URL}/media/3.png`, `${process.env.PUBLIC_URL}/media/4.png`, `${process.env.PUBLIC_URL}/media/5.png`,
    `${process.env.PUBLIC_URL}/media/6.png`, `${process.env.PUBLIC_URL}/media/7.png`, `${process.env.PUBLIC_URL}/media/8.png`,
    `${process.env.PUBLIC_URL}/media/9.png`, `${process.env.PUBLIC_URL}/media/10.png`],
    privacyPolicy: '',
  },
  {
    key: 'lightcube',
    title: 'Light cube',
    learnMoreLink: '/games/lightcube',
    featuredImage: `${process.env.PUBLIC_URL}/lightcube/featured.jpeg`,
    shouldShowInMainPage: true,
    releaseStatus: ReleaseStatus.Released,
    description: lightCubeDescription,
    releaseLinks: {
      [ReleaseTarget.android]: 'https://play.google.com/store/apps/details?id=com.SmakitGames.LightCube',
      [ReleaseTarget.ios]: 'https://apps.apple.com/app/id1593118689',
    },
    videos: [],
    images: [`${process.env.PUBLIC_URL}/lightcube/media/1.png`, `${process.env.PUBLIC_URL}/lightcube/media/2.png`,
    `${process.env.PUBLIC_URL}/lightcube/media/3.png`,`${process.env.PUBLIC_URL}/lightcube/media/4.png`,
    `${process.env.PUBLIC_URL}/lightcube/media/5.png`,],
    privacyPolicy: lightCubePolicy,
  },
];