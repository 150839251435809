import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../common/constants';
import { FeaturedGame, FeaturedGames, Vision } from './';

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.font,
    minHeight: 'calc(100vh - 64px)',
    paddingTop: '64px',
  },
});

export const HomeComponent: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FeaturedGame />
      {/* <FeaturedGames /> */}
      <Vision />
    </div>
  );
};
