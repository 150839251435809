import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import { colors, gameDetails, ReleaseStatus, ReleaseTarget } from '../common/constants';
import { Media } from './';

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.font,
    minHeight: 'calc(100vh - 64px)',
    paddingTop: '64px',
  },
  header: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/gamesHeader.jpg)`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '200px',
    width: '100%',
    textAlign: 'center',
    fontSize: '2em',
    color: colors.font,
    filter: 'saturate(150%)',
    lineHeight: '200px',
    fontWeight: 600,
    WebkitTextStroke: '1px',
    WebkitTextStrokeColor: colors.accentFont,
    ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
      height: '64px',
      lineHeight: '64px',
      fontSize: '1.2em',
    },
  },
  mainContent: {
    textAlign: 'justify',
    lineHeight: '1.8em',
    fontSize: '1.2em'
  },
  mainContentContainer: {
    padding: '20px 20%',
    ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
      padding: '10px 5px',
    },
  },
  availableContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.main,
    padding: '20px',
    minHeight: '200px',
  },
  image: {
    maxWidth: '120px',
    margin: '0 10px',
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      filter: 'brightness(1.75)',
    },
  },
  icon: {

  },
  availableContainerHeader: {
    color: colors.font,
    fontSize: '1.3em',
  },
  availableIcons: {
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mediaContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: colors.accent,
    flexDirection: 'column',
  },
  mediaHeader: {
    fontSize: '1.6em',
    textDecoration: 'underline',
    fontWeight: 600,
  },
});

const getReleaseIcon = (target: ReleaseTarget) => {
  switch(target) {
    case ReleaseTarget.android:
      return `${process.env.PUBLIC_URL}/android.png`;
    case ReleaseTarget.ios:
      return `${process.env.PUBLIC_URL}/ios.svg`;
    default:
      return ''
  }
};

export const GameDetails: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<{id: string}>();
  const currentGame = gameDetails.find(gameDetail => gameDetail.key === id);
  if (!currentGame) {
    return <div />
  }
  const {title, featuredImage, description, releaseStatus, releaseLinks, images} = currentGame;
  const releaseLinkElements = releaseStatus === ReleaseStatus.Released && releaseLinks && Object.keys(releaseLinks).map(releaseLinkKey => {
    const key = releaseLinkKey as unknown as ReleaseTarget;
    const link = releaseLinks[key];
    const imageUrl = getReleaseIcon(Number(key));
    return (
      <a key={key} href={link} target="_blank" rel="noopener noreferrer" className={classes.link}>
        <img className={classes.image} src={imageUrl} alt="Download" />
      </a>
    );
  });
  const availableContainer = releaseStatus === ReleaseStatus.ComingSoon ? (
    <div className={`${classes.availableContainer} ${classes.availableContainerHeader}`}>
      Coming soon
    </div>
  ): (
    <div className={classes.availableContainer}>
      <div className={classes.availableContainerHeader}>
        Available on
      </div>
      <div className={classes.availableIcons}>
        {releaseLinkElements}
      </div>
    </div>
  );
  return (
    <div className={classes.root}>
      <Helmet>
        <meta property="og:title" content={title} />
        <meta property="og:description" content="Download from play store or app store." />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/lightcube/featured.jpeg`} />
      </Helmet>
      <div className={classes.header} style={{backgroundImage: `url(${featuredImage})`}}>
        {title}
      </div>
      <div className={classes.mainContentContainer}>
        <div className={classes.mainContent}>
          {description}
        </div>
      </div>
      {availableContainer}
      <div className={classes.mediaContainer}>
        <div className={classes.mediaHeader}>
          Images
        </div>
        <Media images={images} />
      </div>
    </div>
  );
};
