import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { gameDetails, ReleaseTarget } from '../common/constants';

export const GameStoreDirectLink: React.FC = () => {
  const { id } = useParams<{id: string}>();
  const history = useHistory();
  const currentGame = gameDetails.find(gameDetail => gameDetail.key === id);
  React.useEffect(() => {
    if (currentGame) {
      if (navigator.userAgent.includes('android') || navigator.userAgent.includes('Android')) {
        const androidLink = currentGame?.releaseLinks?.[ReleaseTarget.android];
        if (androidLink) {
          window.location.replace(androidLink);
          return;
        }
      }
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        const iosLink = currentGame?.releaseLinks?.[ReleaseTarget.ios];
        if (iosLink) {
          window.location.replace(iosLink);
          return;
        }
      }
      history.push(`/games/${id}`);
      return;
    }

  }, [currentGame, history, id]);
  return <div />;
};
