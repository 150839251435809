import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { colors, gameDetails } from '../common/constants';
import { GameCard } from '../common';

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.font,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px 0',
  },
  header: {
    fontSize: '1.6em',
    textDecoration: 'underline',
    fontWeight: 600,
  },
  gamesLink: {
    color: colors.accentFont,
  },
  featuredContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
});

export const FeaturedGames: React.FC = () => {
  const classes = useStyles();
  const gameCardComponents = gameDetails.filter(gameDetail => gameDetail.shouldShowInMainPage).map((gameDetail, index) => {
    const {title, releaseStatus, learnMoreLink, releaseLinks, featuredImage} = gameDetail;
    return (
      <GameCard
        key={index}
        title={title}
        releaseStatus={releaseStatus}
        learnMoreLink={learnMoreLink}
        releaseLinks={releaseLinks}
        featuredImage={featuredImage}
      />
    );
  });
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Link to="/games" className={classes.gamesLink}>GAMES</Link>
      </div>
      <div className={classes.featuredContainer}>
        {gameCardComponents}
      </div>
    </div>
  );
};
