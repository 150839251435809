import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import { GameDetails, GamePrivacyPolicy, GameStoreDirectLink } from './';

export const GameRouter: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <GameDetails />
      </Route>
      <Route path={`${path}/privacy`}>
        <GamePrivacyPolicy />
      </Route>
      <Route path={`${path}/store`}>
        <GameStoreDirectLink />
      </Route>
    </Switch>
  );
};
