import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../common';

const useStyles = makeStyles({
  root: {
    color: colors.font,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '10px',
    ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
      gridTemplateColumns: '1fr',
    },
  },
  imageContainer: {
    display: 'block',
    textDecoration: 'none',
    color: colors.font,
    '&:hover': {
      backgroundColor: colors.main,
      filter: 'brightness(1.25)',
    },
  },
  image: {
    width: '280px',
    height: '140px',
    objectFit: 'cover',
  },
});

interface Props {
  images: string[];
}

export const Media: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { images } = props;
  const media = images.map((imageLink, index) => {
    return (
      <a key={index} className={classes.imageContainer} href={imageLink} target="_blank" rel="noopener noreferrer">
        <img src={imageLink} alt={imageLink} className={classes.image} />
      </a>
    );
  });
  return <div className={classes.root}>{media}</div>;
};
