import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { colors } from '../common/constants';
const useStyles = makeStyles({
  root: {
    backgroundColor: colors.accent,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px 0',
  },
  header: {
    fontSize: '1.6em',
    textDecoration: 'underline',
    fontWeight: 600,
  },
  gamesLink: {
    color: colors.main,
  },
  textContainer: {
    marginTop: '10px',
    padding: '0 5px',
    textAlign: 'justify',
  },
});

export const Vision: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Link to="/about" className={classes.gamesLink}>Our Mission</Link>
      </div>
      <div className={classes.textContainer}>
        Develop engaging and creative games on various platforms without compromising on quality.
      </div>
    </div>
  );
};
