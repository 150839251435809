import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { colors, gameDetails } from '../common/constants';

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.font,
    minHeight: 'calc(100vh - 64px)',
    paddingTop: '64px',
    padding: '20px',
  },
});


export const GamePrivacyPolicy: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<{id: string}>();
  const currentGame = gameDetails.find(gameDetail => gameDetail.key === id);
  if (!currentGame) {
    return (<div></div>)
  }
  const { privacyPolicy } = currentGame;
  return (
    <div className={classes.root}>
      <ReactMarkdown>
        {privacyPolicy}
      </ReactMarkdown>
    </div>
  );
};
