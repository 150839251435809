import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link, useHistory } from 'react-router-dom';
import { colors, ReleaseStatus, ReleaseLinks } from '../common/constants';

const useStyles = makeStyles({
  root: {
    width: 'min(320px, 100%)',
    boxShadow: `0 1px 2px 0 ${colors.accent}`,
    transition: '0.3s',
    margin: '25px',
    height: '500px',
    textAlign: 'center',
    '&:hover': {
      boxShadow: `0 4px 8px 0 ${colors.accent}`,
      filter: 'saturate(150%)',
    },
  },
  alternateRoot: {
    width: 'min(100%, 320px)',
    cursor: 'pointer',
  },
  imageContainer: {
    height: '80%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  titleContainer: {
    fontSize: '1.5em',
    fontWeight: 400,
  },
  learnMoreButton: {
    borderRadius: '15px',
    padding: '5px',
    fontSize: '1em',
    minWidth: '200px',
    cursor: 'pointer',
    textAlign: 'center',
    textDecoration: 'none',
    color: colors.main,
    backgroundColor: colors.accent,
    boxSizing: 'border-box',
    lineHeight: '25px',
    border: `2px solid ${colors.font}`,
    '&:hover': {
      backgroundColor: colors.font,
      border: `2px solid ${colors.accent}`,
    },
  },
  learnMoreButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  detailsContainer: {
    height: '20%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

interface Props {
  title: string;
  featuredImage: string;
  releaseStatus: ReleaseStatus;
  learnMoreLink: string;
  releaseLinks?: ReleaseLinks;
  alternateWidth?: boolean;
}

export const GameCard: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const {featuredImage, learnMoreLink} = props;
  const rootClass = `${classes.root} ${classes.alternateRoot}`;
  const linkElement = (
    <div className={classes.learnMoreButtonContainer}>
      <Link to={learnMoreLink} className={classes.learnMoreButton}>Learn more</Link>
    </div>
  );
  const handleClick = () => {
    history.push(learnMoreLink);
  };
  return (
    <div className={rootClass} onClick={handleClick}>
      <div className={classes.imageContainer} style={{backgroundImage: `url(${featuredImage})`}}>
      </div>
      <div className={classes.detailsContainer}>
        {/* <span className={classes.titleContainer}>{title}</span> */}
        {linkElement}
      </div>
    </div>
  );
};
